import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./assets/global.scss";
Vue.config.productionTip = false

// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAbUlrhC6B5bFqdgxJL0LP7egkozrrtG9o",
  authDomain: "nongshim-82253.firebaseapp.com",
  projectId: "nongshim-82253",
  storageBucket: "nongshim-82253.appspot.com",
  messagingSenderId: "222361567975",
  appId: "1:222361567975:web:08a384bfc423c5e4eb1842",
  measurementId: "G-457887DWBC"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.prototype.$analytics = firebase.analytics();
Vue.prototype.$firestore = firebase.firestore();
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
