<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      imageLoad: false,
      allImages: [
        "image/1.png",
        "image/2.png",
        "image/3.png",
        "image/4.png",
        "image/5.png",
        "quiz/1번질문.png",
        "quiz/2번질문.png",
        "quiz/3번질문.png",
        "quiz/4번질문.png",
        "quiz/5번질문.png",
        "quiz/6번질문.png",
        "quiz/7번질문.png",
        "quiz/8번질문.png",
        "quiz/9번질문.png",
        "quiz/10번질문.png",
        "quiz/11번질문.png",
        "quiz/12번질문.png",
        "quiz/오프닝.png",
        "result/ENFJ.png",
        "result/ENFP.png",
        "result/ENTJ.png",
        "result/ENTP.png",
        "result/ESFJ.png",
        "result/ESFP.png",
        "result/ESTJ.png",
        "result/ESTP.png",
        "result/INFJ.png",
        "result/INFP.png",
        "result/INTJ.png",
        "result/INTP.png",
        "result/ISFJ.png",
        "result/ISFP.png",
        "result/ISTJ.png",
        "result/ISTP.png"
      ]
    };
  },
  created() {
    this.loadImage();
  },
  methods: {
    loadImage() {
      try {
        Promise.all(
          this.allImages.map(async src => {
            return await this.preLoadImage(src);
          })
        )
          .then(() => {
            this.imageLoad = true;
          })
          .catch(e => {
            console.error(e);
          });
      } catch (e) {
        console.error(e);
      }
    },
    preLoadImage(src) {
      return new Promise(resolve => {
        let img = new Image();
        img.src = `https://rbti-ns.com/${src}`;
        img.onload = () => {
          resolve(true);
        };
        img.onerror = () => {
          resolve(true);
        };
      });
    }
  }
};
</script>